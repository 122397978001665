<template>
    <div>
        <table v-if="reservations.length > 0" class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Data</th>
                <th>Offerta</th>
                <th>Utente</th>
                <th>Canale</th>
                <th>Stato</th>
                <th>Pagamento</th>
            </tr>
            </thead>
            <tbody id="reservation-list">
              <ReservationRow v-for='reservation in reservations' :reservation="reservation" v-bind:key ='reservation.id'/>
            </tbody>
        </table>

        <span v-else class="text-muted">
            Non sono presenti prenotazioni
        </span>

    </div>
</template>
<script>

    import ReservationRow from "./ReservationRow"
    export default {
        name: "ReservationTable",
        components:{ReservationRow},
        props: {
            reservations: {
                type: Array,
                required: true
            }
        }
    }
</script>
