<template>

    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
           <a class="btn btn-default btn-sm" id="delete_filters" @click.prevent.stop="removeFilterReservations">
            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                Cancella Filtri
            </a>
            <button type="button" class="close" @click="$emit('close-filter-reservations-modal')"><span aria-hidden="true">&times;</span></button>
        </template>
        <template v-slot:body>
            <div class="form-horizontal">
                <div class="form-group">
                    <label for="time-options" class="col-xs-4 control-label">Data</label>
                    <div class="col-xs-8">
                        <select class="form-control" id="time-options" v-model='date'>
                            <option v-for = "time in dateOptions" :key = "time.value" :value="time.value" >{{time.name}}</option>
                            <option :value="null">Tutte le prenotazioni</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="user-options" class="col-xs-4 control-label">Utente</label>
                    <div class="col-xs-8">
                        <select class="form-control" id="user-options" v-model="filter.creator">
                            <option :value="null">Tutti gli utenti</option>
                            <option v-for="creator in creatorsOptions" :key="creator.value" :value="creator.value">{{creator.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="payment-mode-options" class="col-xs-4 control-label">Pagamento</label>
                    <div class="col-xs-8">
                        <select class="form-control" id="payment-mode-options" v-model="filter.payment_mode">
                            <option :value="null">Tutti i metodi</option>
                            <option v-for="payment in paymentOptions" :key="payment.value" :value="payment.value">{{payment.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="ticket_code" class="col-xs-4 control-label">Codice biglietto</label>
                    <div class="col-xs-8">
                        <input class="form-control" type="text" id="ticket_code" v-model="filter.ticket_code">
                    </div>
                </div>
                <div class="form-group">
                    <label for="customer_name" class="col-xs-4 control-label">Nome cliente</label>
                    <div class="col-xs-8">
                        <input class="form-control" type="text" id="customer_name" v-model="filter.customer_name">
                    </div>
                </div>
                <div class="form-group">
                    <label for="customer_email" class="col-xs-4 control-label">Email</label>
                    <div class="col-xs-8">
                        <input class="form-control" type="text" id="customer_email" v-model="filter.customer_email">
                    </div>
                </div>
                <div class="form-group">
                    <label for="customer_nationality" class="col-xs-4 control-label">Nazione</label>
                    <div class="col-xs-8">
                        <select class="form-control" id="customer_nationality" v-model="filter.nationality"> <!--TODO-->
                            <option :value="null">Tutte le nazioni</option>
                            <option v-for="(stringName, isoCountry) in COUNTRIES" :key="isoCountry" :value="isoCountry" :disabled="!stringName">
                                {{stringName || isoCountry}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <a @click.prevent.stop="filterReservations" class="btn btn-primary btn-lg btn-block" id="apply_filters">
                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                Applica Filtri
            </a>
        </template>
    </ModalContainer>

</template>

<script>
    import ModalContainer from "./ModalContainer"
    import {COUNTRIES} from "../../common/countries";
    import baseMixin from "../../common/baseMixin";
    export default {
        name: "ReservationFilterModal",
        components: { ModalContainer },
        mixins: [baseMixin],
        props: {
            visible: {
                type: Boolean,
                required: true
            }
        },
        data: () => {
            return {
                COUNTRIES: COUNTRIES,
                loading: false,
                date: null,
                creatorsOptions: [],
                paymentOptions: [],
                dateOptions: [],
                filter: {
                    start_date: null,
                    end_date: null,
                    creator: null,
                    payment_mode: null,
                    ticket_code: null,
                    customer_name: null,
                    customer_email: null,
                    nationality: null
                },
                defaultFilter: {
                    start_date: null,
                    end_date: null,
                    creator: null,
                    payment_mode: null,
                    ticket_code: null,
                    customer_name: null,
                    customer_email: null,
                    nationality: null
                 }
            }
        },
        methods: {
            filterReservations() {
                this.$emit('filter-reservation-callback', this.filter)
            },
            removeFilterReservations() {
            this.$emit('remove-filter-reservation-callback', this.defaultFilter)
          },
        },
        mounted() {
            this.creatorsOptions = this.Keys.CREATORS_OPTIONS();
            this.paymentOptions = this.Keys.PAYMENT_MODE_OPTIONS();
            this.dateOptions = this.Keys.RESERVATIONS_DATE_OPTIONS();
            this.date = 'today';
        },
        watch: {
            date: function(time) {
                let filter = this.Utils.getStartEndDateFromString(time);
                this.filter.start_date = filter.start_date;
                this.filter.end_date = filter.end_date;
            }
        }
    }
</script>
