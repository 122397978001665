<template>
    <tr class="clickable-row">
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{reservation.id}}</router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{Utils.getDateStringFromISO(reservation.date)}}</router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{StringsHelper.getOfferta(reservation)}}</router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}"> <span v-if="reservation.creator">{{reservation.creator.display_name}}</span> </router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}"><span class="label label-default"> {{StringsHelper.getChannel(reservation.channel)}}</span></router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}"><span class="label" :class="LabelClassHelper.getLabelClassReservationState(reservation.state)"> {{StringsHelper.getReservationState(reservation.state)}}</span></router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{StringsHelper.getPaymentMode(reservation.payment_mode)}}</router-link></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'

    export default {
        name: "ReservationRow",
        mixins: [baseMixin],
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
      methods:{

      }
    }
</script>